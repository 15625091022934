import Cookies, { CookieAttributes } from "js-cookie"


export function getLocale(): any {
    return Cookies.get('locale')
}
export function setLocale(locale: string): any {
    let options: CookieAttributes = {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 99)),
        path: '/',
        domain: MULTI_EMPRESA_COOKIES_DOMAIN,
        secure: ENVIRONMENT != 'development',
        sameSite: 'Lax'
    }
    Cookies.set('locale', locale, options)
}

export function setLocaleFromRails(): any {
    const currentLocale = getLocale();

    if (currentLocale != RAILS_LOCALE) {
        setLocale(RAILS_LOCALE);
    }
}

import Cookies, { CookieAttributes } from "js-cookie"

export function autoRedirectFromWebview(followUrl: string) {
    console.log('autoRedirectFromWebview')

    const url = new URL(window.location.href);
    const params = url.searchParams
    const embeded = params.get('embeded');
    const token = params.get('token');

    // seta os cookies de jwt e redireciona
    if (embeded && token) {
        // embeded
        let optionsForEmbede: CookieAttributes = {
            expires: new Date().getDate() + 1000,
            path: '/',
            domain: MULTI_EMPRESA_COOKIES_DOMAIN,
            secure: ENVIRONMENT != 'development',
            sameSite: 'Lax'
        }

        Cookies.set('embeded', embeded, optionsForEmbede);


        // token
        let optionsForToken: CookieAttributes = {
            expires: new Date().getDate() + 1000,
            path: '/',
            domain: MULTI_EMPRESA_COOKIES_DOMAIN,
            secure: ENVIRONMENT != 'development',
            sameSite: 'Lax'
        }

        Cookies.set(JWT_COOKIE_NAME, token, optionsForToken)

        // redireciona automaticamente para a url de comprar
        window.location.href = followUrl;
    }

}
import Cookies, { CookieAttributes } from "js-cookie"


export function getDeviceToken(): any {
    return Cookies.get('dev')
}
export function setDeviceToken(token: string): any {
    let options: CookieAttributes = {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 99)),
        path: '/',
        domain: MULTI_EMPRESA_COOKIES_DOMAIN,
        secure: ENVIRONMENT != 'development',
        sameSite: 'Lax'
    }
    Cookies.set('dev', token, options)
}

export function generateIfNotExists(): any {
    const currentToken = getDeviceToken();
    if (currentToken === undefined || currentToken == null) {
        const newToken: string = Array.from(Array(30), () => Math.floor(Math.random() * 36).toString(36)).join('');
        setDeviceToken(newToken + "-site-v2");
    }
}